.iconA {
  display: inline-block;
  padding: 10px;
  margin: 0;

  text-decoration: none;
  color: inherit;

  font-size: 34px;
}

.iconA:hover {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  width: fit-content;
  padding: 8px;
  border-radius: 6px;
  background-color: rgb(0, 0, 0);

  top: 60px;

  color: white;
  font-size: 16px;

  transition: all 0.13s ease-in-out;
}

@media (max-width: 600px) {
  .tooltip {
    color: transparent;
    background-color: transparent;
    font-size: 0;
    width: 0px;
    height: 0px;
  }
}
