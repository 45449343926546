:root {
  --brandPrimary-color: rgb(68, 182, 226);
  --brandSecondary-color: rgb(68, 153, 237);
  --brandLightPrimary-color: #fff7ea;
  --brandDarkPrimary-color: rgb(45, 93, 112);
  --pagePrimary-color: #fff7ea;
  --pageSecondary-color: rgb(68, 182, 226);
  --pageSecondaryFadeTo-color: rgb(146, 212, 237);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
