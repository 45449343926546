.link {
  transition: all 0.6s ease-in-out;
}

.btnSlide {
  background-color: transparent;
  color: var(--brandPrimary-color);
  padding: 0.5em 1em;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: color 150ms ease-in-out;
  border: 2px solid var(--brandPrimary-color);
  font-size: inherit;
}

.btnSlide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(
    to bottom right,
    var(--brandPrimary-color),
    var(--brandSecondary-color)
  );

  transform: scaleX(0);
  transform-origin: left;
  transition: transform 150ms ease-in-out;
}

.btnSlide:hover::before {
  transform: scaleX(1);
}

.btnSlide:hover {
  color: white;
}
