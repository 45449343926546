.headerContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.iconContainer {
  width: clamp(220px, 30%, 400px);
  float: right;

  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;

  color: black;
}

@media (max-width: 600px) {
  .iconContainer {
    width: 100%;
    height: 50px;
    float: none;
    background-color: var(--brandLightPrimary-color);
  }
}
