.repoContainer {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: #ffffff;
  border: 2px solid #484f58;
  font-size: 1.5rem;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.repoHead,
.repoContent {
  padding: 1rem;
}

.repoHead {
  background-color: #010409;
  font-size: 1.5rem;
  font-family: inherit;
  border-bottom: 1px solid #484f58;
}

.repoContent {
  background-color: #21262d;
  padding-top: 20px;
  text-wrap: wrap;
}

.repoName {
  color: #1f6feb;
  margin: 0;
  text-decoration: none;
  display: inline;
}
.repoName:hover {
  text-decoration: underline;
}

.repoDesc {
  color: #7d8590;
  font-size: 1rem;
}

.public {
  display: inline;
  color: #7d8590;
  border: 1px solid #7d8590;
  padding: 0.2rem 0.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  transform: translateY(0.5rem);
}

.repoStats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.repoStat {
  color: #7d8590;
  font-size: 0.8rem;
}
