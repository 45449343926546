.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;

  width: fit-content;
  font-size: 18px;
}

@media (max-width: 1000px) {
  .buttonsContainer {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .buttonsContainer {
    margin: 0 auto;
    font-size: 14px;
    flex-wrap: wrap;
  }
}

@media (max-width: 350px) {
  .buttonsContainer {
    font-size: 12.3px;
  }
}
