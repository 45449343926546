.plugsContainer {
  width: fit-content;
  margin: 30px auto;

  display: flex;
  flex-direction: column;
  justify-content: left;
}

.plugDiv {
  font-size: 20px;
  width: fit-content;
  white-space: nowrap;
  height: 40px;
}

.btnContainer {
  width: 50px;
  height: 50px;
  display: inline-block;
  transform: translate(0, 8px);
}

.plugLink {
  text-decoration: none;
  color: black;
  margin: 2px;
}

.plugStrong {
  color: var(--brandPrimary-color);
  margin-left: 10px;
  text-decoration: underline;
}

.backToTopBtn {
  width: 25px;
  height: 25px;
  margin: 60px auto 30px auto;

  background-color: var(--brandPrimary-color);
  color: var(--brandLightPrimary-color);

  font-size: 20px;
  text-align: center;
  align-items: baseline;

  border-radius: 100%;
  padding: 10px;

  transition: all 0.13s ease-in-out;
}

.backToTopBtn:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.arrowIcon {
  transform: translate(0.3px, 2px);
}

@media (max-width: 600px) {
  .plugDiv {
    font-size: 16px;
  }
}

@media (max-width: 390px) {
  .plugDiv {
    font-size: 11px;
  }

  .plugStrong {
    margin-left: 3px;
  }
}
