.tile {
  background-color: rgb(145, 145, 145);
  border: 1px solid black;
  width: 40px;
  height: 40px;
  font-size: 33px;
  display: inline;
  text-align: center;
  line-height: 38px;
  user-select: none;
}

.tile:hover {
  cursor: pointer;
}

.unknown {
  font-size: 0px;
  background-color: rgb(169, 169, 169);
}
