.gameDiv {
  width: fit-content;
  padding: 20px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.board {
  background-color: black;
  width: fit-content;
  text-align: center;
}

.row {
  width: fit-content;
  display: flex;
  justify-content: space-evenly;
}

.face {
  margin: 0;
  padding: 0;
  color: yellow;
  font-size: 50px;
}

.face:hover {
  color: rgb(214, 214, 13);
  cursor: pointer;
}

.smileyButton {
  width: 70px;
  height: 70px;
  background-color: transparent;
  padding: 0;

  margin: 0 auto;
  border: none;
}

.smileyButton:hover {
  color: rgb(214, 214, 13);
  cursor: pointer;
}
