.verticalAlignContainer {
  height: 100vh;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.titleContainer {
  margin-left: clamp(10px, 20vw, 400px);
}

.landingText {
  font-size: 40px;
  margin: 0;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.strong {
  color: var(--brandPrimary-color);
}

@media (max-width: 1000px) {
  .titleContainer {
    margin-left: 65px;
  }
  .landingText {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .landingText {
    font-size: 25px;
    white-space: wrap;
    text-align: center;
  }
  .titleContainer {
    margin: 0 auto;
    width: fit-content;
  }
}
