.singleProjectContainer,
.altSingleProjectContainer {
  height: fit-content;
  width: 70%;
  margin: 50px auto;

  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.altSingleProjectContainer {
  flex-direction: row-reverse;
}

.projInfoContainer,
.imgContainer {
  width: 500px;
  margin: 10px;
}

.projTitle {
  font-size: 20px;
  margin: 0px;
  color: var(--brandPrimary-color);
  text-align: center;
}

.titleLink {
  color: inherit;
}

.titleLink:hover {
  text-decoration: none;
}

.techStackItem {
  background-color: var(--brandDarkPrimary-color);
  color: var(--brandLightPrimary-color);

  width: fit-content;
  margin: 4px;

  font-size: 12px;
  padding: 1px 2px;
  border: 2px solid black;
}

.techStackContainer {
  margin: 0 auto;
  width: fit-content;
  max-width: 98%;

  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  overflow: hidden;
}

.projDesc {
  color: black;
  font-size: medium;
  text-align: center;
  width: 90%;
  margin: 0px auto -20px auto;
}

.buttonsContainer {
  width: fit-content;
  font-size: 18px;
  margin: 30px auto;
}

.imgContainer {
  display: flex;
  align-items: center;
}

.img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 2px 2px 5px var(--brandDarkPrimary-color),
    -2px -2px 5px var(--brandDarkPrimary-color);
}

.btnContainer {
  margin: 0 auto;
  width: fit-content;
}

.moreIcon {
  display: block;
  margin: -10px auto;
  font-size: 2em;
}

.seeMoreBtn,
.seeLessBtn {
  font-family: "Montserrat";
  color: var(--brandPrimary-color);
  border: 2px solid transparent;
  background-color: transparent;
  text-align: center;
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.17s ease-in-out;
}

.seeMoreBtn:hover,
.seeLessBtn:hover {
  cursor: pointer;
}

.seeMoreBtn:hover {
  margin-bottom: 6px;
}

@media (max-width: 600px) {
  .singleProjectContainer,
  .altSingleProjectContainer {
    width: 96%;
  }
  .projDesc {
    width: 96%;
  }
  .buttonsContainer {
    font-size: 13px;
    margin: 25px auto;
  }
  .techStackContainer {
    width: fit-content;
  }
}

@media (max-width: 390px) {
  .buttonsContainer {
    font-size: 10px;
  }
}
