.counterHeader {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
  text-align: center;
  color: var(--brandLightPrimary-color);
  margin-bottom: 30px;
}

.reposContainer {
  width: clamp(270px, 70%, 800px);
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}

.btn {
  background-color: transparent;
  color: var(--brandLightPrimary-color);
  border: 2px solid transparent;
  border-radius: 15px;
  font-size: 26px;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.btn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.063);
  border-color: var(--brandLightPrimary-color);
  transition: 0.02s ease-in-out;
}

.icon {
  vertical-align: middle;
  font-size: 2rem;
}

@media (max-width: 550px) {
  .reposContainer {
    flex-wrap: wrap;
  }
  .btn {
    order: 2;
    background-color: rgba(0, 0, 0, 0.063);
    border-color: var(--brandLightPrimary-color);
  }
  .reposContainer {
    order: 1;
  }
}
