.aboutContainer {
  width: fit-content;
  height: fit-content;
  padding: 10px 0 50px 0;
  margin: 0 auto;
}

.btnSlide {
  background-color: transparent;
  color: white;
  padding: 0.1em 1em;
  border: none;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: color 150ms ease-in-out;
  border: 2px solid white;
  font-size: 0.6em;
}

.btnSlide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: linear-gradient(to bottom right, rgb(213, 213, 213), white);

  transform: scaleX(0);
  transform-origin: left;
  transition: transform 150ms ease-in-out;
}

.btnSlide:hover::before {
  transform: scaleX(1);
}

.btnSlide:hover {
  color: var(--brandPrimary-color);
}

@media (max-width: 1500px) {
  .btnSlide {
    display: none;
  }
}
