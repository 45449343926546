.historyContainer {
  background-color: var(--brandDarkPrimary-color);
  color: var(--brandLightPrimary-color);

  font-size: 16px;
  height: fit-content;
  padding: 16px 0;
  margin: 30px auto;

  width: clamp(250px, 80%, 800px);

  border-radius: 4px;
  transition: all 0.15s ease-in-out;
}

.historyHeading,
.historyParagraphHeading {
  text-align: center;
  font-size: 20px;
  margin: 10px;
}

.historyParagraphHeading {
  font-size: 18px;
}

.showMore {
  background-color: var(--brandDarkPrimary-color);
  border: 2px solid var(--brandLightPrimary-color);
  color: var(--brandLightPrimary-color);

  font-size: 15px;
  padding: 3px 8px;
  font-family: monospace;
  float: right;
  border-radius: 4px;

  transform: translate(-10px, 0px);
  transition: all 0.1s ease-in-out;
}

.showMore:hover {
  transform: translate(-8px, 2px);
  cursor: pointer;
}

@media (max-width: 390px) {
  .historyContainer {
    width: 100%;
  }
}
